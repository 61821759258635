import React, { useState, useEffect } from "react";
import "./Form.css";
import maps from "../../../assets/templates/maps.json";

import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";
Chart.register(...registerables);

const Form = ({ stats, matchList }) => {
  const [activeTab, setActiveTab] = useState("kd");
  const [graphData, setGraphData] = useState({ labels: [], datasets: [] });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const colors = {
    good: "#30c464",
    avg: "#ffc454",
    bad: "#ff4f4f",
  };

  useEffect(() => {
    // Function to create the data object for the graph
    const createGraphData = (statKey) => {
      const dataValues = matchList.map((match) =>
        statKey === "hsPercentage" || statKey === "KAST"
          ? (match[statKey] * 100).toFixed(2)
          : match[statKey].toFixed(2)
      );
      const matchDates = matchList.map((match) =>
        new Date(match.gameStartMillis).toLocaleDateString()
      );

      return {
        labels: matchDates,
        datasets: [
          {
            label: statKey.toUpperCase(),
            data: dataValues,
            borderColor: "#d30011",
            fill: false,
            cubicInterpolationMode: "monotone",
            tension: 0.2,
          },
        ],
      };
    };

    // Update the graph data based on the active tab
    setGraphData(createGraphData(activeTab));
  }, [activeTab, matchList]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          // This callback is for the tooltip title
          title: function (context) {
            // Assuming 'context' is an array and you have access to the original data array
            // Extract the index of the current item
            const index = context[0].dataIndex;
            // Use the index to access the corresponding map and date from your original data
            const mapName = maps[matchList[index].mapId].displayName; // Replace with the actual property name
            const matchDate = new Date(
              matchList[index].gameStartMillis
            ).toLocaleDateString();
            // Return the custom label
            return `${mapName} - ${matchDate}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Start the y-axis from the lowest ADR value or zero
        title: {
          display: false,
        },
        grid: {
          drawBorder: false,
          borderColor: "transparent",
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      x: {
        title: {
          display: false,
        },
        grid: {
          drawBorder: false, // Hide the border of the axes
          borderColor: "transparent",
          drawOnChartArea: false, // Hide the gridlines in the chart area
          drawTicks: false, // Hide the ticks on the x-axis
        },
        ticks: {
          display: false, // Hide the x-axis labels
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3, // You can adjust the thickness of the line here
      },
      point: {
        radius: 4, // Adjust the radius of the points on the line
      },
    },
  };

  return (
    <div className="Form">
      <div className="tabs">
        <button
          onClick={() => handleTabClick("kd")}
          className={activeTab === "kd" ? "active" : ""}
        >
          <h1>K/D Ratio</h1>
          <h2
            style={{
              color:
                stats.kda.avgKDA >= 0.98
                  ? colors.good
                  : stats.kda.avgKDA >= 0.88
                  ? colors.avg
                  : colors.bad,
            }}
          >
            {stats.kda.avgKDA.toFixed(2)}
          </h2>

          <p>Player Average</p>
          <p>1.01</p>
        </button>

        <button
          onClick={() => handleTabClick("acs")}
          className={activeTab === "acs" ? "active" : ""}
        >
          <h1>ACS</h1>
          <h2
            style={{
              color:
                stats.acs.avgACS >= 190
                  ? colors.good
                  : stats.acs.avgACS >= 150
                  ? colors.avg
                  : colors.bad,
            }}
          >
            {stats.acs.avgACS.toFixed(1)}
          </h2>

          <p>Player Average</p>
          <p>196.2</p>
        </button>

        <button
          onClick={() => handleTabClick("kpr")}
          className={activeTab === "kpr" ? "active" : ""}
        >
          <h1>KPR</h1>
          <h2
            style={{
              color:
                stats.kpr.avgKPR >= 0.7
                  ? colors.good
                  : stats.kpr.avgKPR >= 0.58
                  ? colors.avg
                  : colors.bad,
            }}
          >
            {stats.kpr.avgKPR.toFixed(2)}
          </h2>

          <p>Player Average</p>
          <p>0.72</p>
        </button>

        <button
          onClick={() => handleTabClick("dpr")}
          className={activeTab === "dpr" ? "active" : ""}
        >
          <h1>DPR</h1>
          <h2
            style={{
              color:
                stats.dpr.avgDPR >= 0.8
                  ? colors.bad
                  : stats.dpr.avgDPR >= 0.67
                  ? colors.avg
                  : colors.good,
            }}
          >
            {stats.dpr.avgDPR.toFixed(2)}
          </h2>

          <p>Player Average</p>
          <p>0.65</p>
        </button>

        <button
          onClick={() => handleTabClick("adr")}
          className={activeTab === "adr" ? "active" : ""}
        >
          <h1>ADR</h1>
          <h2
            style={{
              color:
                stats.adr.avgADR >= 145
                  ? colors.good
                  : stats.adr.avgADR >= 110
                  ? colors.avg
                  : colors.bad,
            }}
          >
            {stats.adr.avgADR.toFixed(1)}
          </h2>

          <p>Player Average</p>
          <p>156.2</p>
        </button>

        <button
          onClick={() => handleTabClick("hsPercentage")}
          className={activeTab === "hs" ? "active" : ""}
        >
          <h1>HS %</h1>
          <h2
            style={{
              color:
                stats.hsPercentage.avgHSPercentage >= 0.18
                  ? colors.good
                  : stats.hsPercentage.avgHSPercentage >= 0.15
                  ? colors.avg
                  : colors.bad,
            }}
          >
            {(stats.hsPercentage.avgHSPercentage * 100).toFixed(1)} %
          </h2>

          <p>Player Average</p>
          <p>16.7%</p>
        </button>

        <button
          onClick={() => handleTabClick("KAST")}
          className={activeTab === "KAST" ? "active" : ""}
        >
          <h1>KAST %</h1>
          <h2
            style={{
              color:
                stats.KAST.avgKAST >= 0.7
                  ? colors.good
                  : stats.KAST.avgKAST >= 0.6
                  ? colors.avg
                  : colors.bad,
            }}
          >
            {" "}
            {(stats.KAST.avgKAST * 100).toFixed(1)} %
          </h2>

          <p>Player Average</p>
          <p>63.2%</p>
        </button>
      </div>
      <div className="tab-graph">
        <Line data={graphData} options={options} />

        {/* {activeTab === "kd" && <GraphData data={(data, options)} />}
        {activeTab === "kpr" && <GraphData data={stats.kpr.avgKPR} />} */}
        {/* ... other conditional tab content ... */}
      </div>
    </div>
  );
};

export default Form;
